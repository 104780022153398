import { template as template_1fcd0f64d76d42cf90aabbf8fd49b864 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_1fcd0f64d76d42cf90aabbf8fd49b864(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
