import { template as template_16364461b8a64c8f9672b73692d96c89 } from "@ember/template-compiler";
const SidebarSectionMessage = template_16364461b8a64c8f9672b73692d96c89(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
